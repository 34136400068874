import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class JWTTokenService {

    
    decodedToken: { [key: string]: string };

    constructor() {
    }

    setToken(token: string) {
        if (token) {
            localStorage.setItem('_accessToken',token);
        }
    }

    getToken() {
        return localStorage.getItem('_accessToken');
    }

    decodeToken() {
        let jwtToken = localStorage.getItem('_accessToken');
        if (jwtToken) {
            this.decodedToken = jwt_decode(jwtToken);
        }
    }

    getDecodeToken() {
        let jwtToken = localStorage.getItem('_accessToken');
        return jwt_decode(jwtToken);
    }

    getUser(): any {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken : null;
    }

    getEmailId() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.email : null;
    }

    clearAll(){
        localStorage.clear();
    }

    getExpiryTime() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    isTokenExpired(): boolean {
        const expiryTime: number = parseInt(this.getExpiryTime());
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 30000;
        } else {
            return false;
        }
    }
}