import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';


@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  constructor(private authorizationService: AuthorizationService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe(params => {
        let isExternal = params['sf'];
        if (isExternal == true || isExternal == 'true' || isExternal == false || isExternal == 'false') {
          this.authorizationService.authorize(isExternal);
        }
        else {
          this.authorizationService.authorize();
        }
    });
  }

  ngOnInit() {

  }
}
