import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

import { environment } from '../../environments/environment';
import { Notification } from '../../app/models/notification';
import { ViewedNotification } from '../models/viewednotification';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {
  }

  getNotificaitons(): Observable<Notification[]> {
    return this.http.get(`${API_URL}/notification/list`).pipe(
      map((response: any) => {
        const notifications = response;
        return notifications.map((notification) => new Notification(notification));
      }));
  }

  getActiveNotificaitons(): Observable<Notification[]> {
    return this.http.get(`${API_URL}/notification/active`).pipe(
      map((response: any) => {
        const notifications = response;
        return notifications.map((notification) => new Notification(notification));
      }));
  }

  getNotificaitonById(notificationId: number): Observable<Notification> {
    return this.http.get(`${API_URL}/notification/${notificationId}`).pipe(
      map((response: any) => {
        return response;
      }));
  }

  saveNotification(notification: Notification): Observable<Notification> {
    let data = JSON.stringify(notification);
    return this.http.post(`${API_URL}/notification/add`, data).pipe(
      map((response: any) => {
        return response;
      }));
  }

  saveViewedNotification(viewedNotification: ViewedNotification[]): Observable<any> {
    let data = JSON.stringify(viewedNotification);
    return this.http.post(`${API_URL}/notification/addViewedNotification`, data).pipe(
      map((response: any) => {
        return response;
      }));
  }

  updateNotification(notification: Notification): Observable<Notification> {
    let data = JSON.stringify(notification);
    return this.http.post(`${API_URL}/notification/update`, data).pipe(
      map((response: any) => {
        return response;
      }));
  }

  deleteNotification(notificationId: number): Observable<Notification> {
    return this.http.delete(`${API_URL}/notification/${notificationId}`).pipe(
      map((response: any) => {
        return response;
      }));
  }
}