import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { AccessedReport } from '../models/accessedreport';
import { environment } from '../../environments/environment';
import { EmbedConfig } from '../models/embedconfig';
import { ReportPdf } from '../models/reportpdf';

const API_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(private http: HttpClient) {
    }

    saveUserReportDetails(accessedReport: AccessedReport): Observable<AccessedReport> {
        let data = JSON.stringify(accessedReport);
        return this.http.post(`${API_URL}/report/add`, data).pipe(
            map((response: any) => {
                return response;
            }));
    }

    getUserListReportDetails(): Observable<AccessedReport[]> {
        return this.http.get(`${API_URL}/report/list`).pipe(
            map((response: any) => {
                const reports = response;
                return reports.map((report) => new AccessedReport(report));
            }));
    }

    getEmbeddedToken(reportType,language): Observable<EmbedConfig> {
        return this.http.get(`${API_URL}/embedreport/report/${reportType}/${language}`).pipe(
            map((response: any) => {
                //localStorage.setItem("embedToken",JSON.stringify(response));
                return response;
            }));
    }

    getReportAsPdf(reportPdf: ReportPdf): Observable<any> {	
        let data = JSON.stringify(reportPdf);	
        return this.http.post(`${API_URL}/embedreport/downloadpdf`,data,{	
            'responseType'  : 'arraybuffer' as 'json'	
          }).pipe(	
            map((response: any) => {	
                return response;	
            }));	
    }
}