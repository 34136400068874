export class Notification {
    id: number;
    userId: string;
    date: Date;
    description: string;
    status: boolean;
    createdBy: string;
    createdDate: Date;
    notificationType: string;
    fromDate: Date;
    toDate: Date;

    constructor(private notificaiton?: Notification) {
        notificaiton && notificaiton.id ? this.id = notificaiton.id : this.id = 0;
        notificaiton && notificaiton.userId ? this.userId = notificaiton.userId : this.userId = "";
        notificaiton && notificaiton.date ? this.date = notificaiton.date : this.date = null// new Date();
        notificaiton && notificaiton.description ? this.description = notificaiton.description : this.description = "";
        notificaiton && notificaiton.status ? this.status = notificaiton.status : this.status = true;
        notificaiton && notificaiton.createdBy ? this.createdBy = notificaiton.createdBy : this.createdBy = '';
        notificaiton && notificaiton.createdDate ? this.createdDate = notificaiton.createdDate : this.createdDate = new Date();
        notificaiton && notificaiton.notificationType ? this.notificationType = notificaiton.notificationType : this.notificationType = '';
        notificaiton && notificaiton.fromDate ? this.fromDate = notificaiton.fromDate : this.fromDate = null;
        notificaiton && notificaiton.toDate ? this.toDate = notificaiton.toDate : this.toDate = null;
 }
}
