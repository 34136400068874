import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'avp-helpguide',
  templateUrl: './avp-helpguide.component.html',
  styleUrls: ['./avp-helpguide.component.css']
})
export class AvpHelpguideComponent implements OnInit {
  @Output() closeHelpGide = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  closeNav(){
    this.closeHelpGide.emit();
  }
}
