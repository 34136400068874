export class AccessedReport{
    id: number;                   
    userId: string;
    role: string;
    emailId: string;
    userName: string;       
    reportId: string;
    reportName: string;
    school: string;
    date: Date;
    status: boolean;

    constructor(private accessedReport?: AccessedReport) {
        accessedReport && accessedReport.id ? this.id = accessedReport.id : this.id = 0;
        accessedReport && accessedReport.userId ? this.userId = accessedReport.userId : this.userId = "";
        accessedReport && accessedReport.role ? this.role = accessedReport.role : this.role = "";
        accessedReport && accessedReport.emailId ? this.emailId = accessedReport.emailId : this.emailId = "";
        accessedReport && accessedReport.userName ? this.userName = accessedReport.userName : this.userName = "";
        accessedReport && accessedReport.reportId ? this.reportId = accessedReport.reportId : this.reportId = "";
        accessedReport && accessedReport.reportName ? this.reportName = accessedReport.reportName : this.reportName = "";
        accessedReport && accessedReport.school ? this.school = accessedReport.school : this.school = "";
        accessedReport && accessedReport.date ? this.date = accessedReport.date : this.date = new Date();
        accessedReport && accessedReport.status ? this.status = accessedReport.status : this.status = true;
    }
}