import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NotificationListComponent } from './components/notification/notification-list.component';
import { ReportComponent } from './components/report/report.component';
import { UserListComponent } from './components/user/user-list.component';
import { AzureAdUserComponent } from './components/user/azure-ad-user.component';
import { AuthGuard } from './auth.guard';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { AaccessdeniedComponent } from './components/accessdenied/accessdenied.component';
import { AccessTokenResolver } from './comman/authentication-resolver';
import { SchoolResolver } from './comman/school.resolver';


export const routes: Routes = [
  {
    path: 'authenticate', component: AuthenticationComponent
  },
  { path: 'callback', component: CallbackComponent },
   {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard]
  },
   { path: 'report', component: ReportComponent, canActivate: [AuthGuard],
   resolve: {
    school: SchoolResolver
  }
   },
   { path: 'accessdenied', component: AaccessdeniedComponent },
   { path: '**', redirectTo: 'authenticate' }

 
];

export class AppRoutingModule { }