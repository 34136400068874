export class ViewedNotification {
    id: number;
    notificationId: number;
    userId: string;
    notificationDate: Date;

    constructor(data?: any) {
        data && data.id ? this.id = data.id : this.id = 0;
        data && data.notificationId ? this.notificationId = data.notificationId : this.notificationId = 0;
        data && data.userId ? this.userId = data.userId : this.userId = null;
        data && data.notificationDate ? this.notificationDate = data.notificationDate : this.notificationDate = new Date();
    }
}