import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { School } from '../models/school';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl + '/school';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {

  constructor(private http: HttpClient) {
  }

  getSchoolListByUserId(): Observable<School[]> {
    return this.http.get(`${API_URL}`).pipe(
      map((response: any) => {
        const schools = response;
        return schools.map((school) => new School(school));
      }));
  }

  getAllSchool(): Observable<School[]> {
    return this.http.get(`${API_URL}/schoolList`).pipe(
      map((response: any) => {
        const schools = response;
        return schools.map((school) => new School(school));
      }));
  }
}