export class AdUser{
    id: string;
    displayName: string;
    givenName: string;
    surname: string;
    userPrincipalName: string;
    mail: string;

    constructor(private adUser?: AdUser){
        adUser && adUser.id ? this.id = adUser.id : this.id = null;
        adUser && adUser.displayName ? this.displayName = adUser.displayName : this.displayName = null;
        adUser && adUser.givenName ? this.givenName = adUser.givenName : this.givenName = null;
        adUser && adUser.surname ? this.surname = adUser.surname : this.surname = null;
        adUser && adUser.userPrincipalName ? this.userPrincipalName = adUser.userPrincipalName : this.userPrincipalName = null;
        adUser && adUser.mail ? this.mail = adUser.mail : this.mail = null;
    }
}