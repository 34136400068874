import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { Programme } from '../models/programme';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ProgrammeService {
    constructor(private http: HttpClient) {
    }

    getProgrammeListByUserIdSchoolId(schoolId: string): Observable<Programme[]> {
     
      return this.http.get(`${API_URL}/programme/${schoolId}`).pipe(
        map((response: any) => {
          const programmes = response;
          return programmes.map((programme) => new Programme(programme));
        }));
    }

    getProgrammeListBySchoolId(schoolId: string): Observable<Programme[]> {
     
      return this.http.get(`${API_URL}/programmelist/${schoolId}`).pipe(
        map((response: any) => {
          const programmes = response;
          return programmes.map((programme) => new Programme(programme));
        }));
    }
}