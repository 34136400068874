import { Component, OnInit } from '@angular/core';
import { FooterLink, HelperService } from '../../comman/helper.service';
import { AppService } from '../../services/app.service';
import { LANGUAGE } from '../../models/language';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerLink: FooterLink;
  selectedLanguage = '';
  languageOptions = LANGUAGE;
  constructor(private appService: AppService, private helper: HelperService) {
    this.footerLink = { copyrightUrl: '', languagePolicyUrl: '', termsAndConditionsUrl: '', iBprivacyPolicyUrl: '' }
  }

  ngOnInit() {
    let self = this;
    this.appService.language.subscribe(response => {
      let data = this.languageOptions.filter(language => language.name == response)[0];
      if (data) {
        let selectedLanguage = data.id;
        self.footerLink = self.helper.footerLinkUrl(selectedLanguage);
      }
    });
  }
}
