import { OnInit, Component } from '@angular/core';

import { Notification } from '../../models/notification'
import { NotificationService } from '../../services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
    selector: 'notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
    notifications: Notification[];
    currentUser: User;
    notification: Notification;
    filterNotification: string = '';
    messageSummary: string = '';
    notificationDate = new Date();
    dateValue = new Date();
    messageValue = "";
    defaultDate: any;
    SelectedDate: any = { startDate: '', endDate: '' };
    SelectedDateTime: any = { startDate: '', endDate: '' };

    ranges: any = {
        Today: [moment(), moment().add(1, 'days')],
        };

    constructor(private notificationService: NotificationService, private userService: UserService, private spinner: NgxSpinnerService) {
        this.notification = new Notification();
        this.currentUser = new User();
        this.currentUser = this.userService.getUser();
    }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications() {
        this.spinner.show();
        this.notificationService.getNotificaitons().subscribe(notifications => {
            this.spinner.hide();
            this.notifications = notifications;
            this.notifications = this.notifications.sort((a: any, b: any) => {
                return a.createdDate - b.createdDate;
            });
        });
    }

    onSelect(date: any) {
        this.notification.date = date;
    }

    add() {
        this.notification = new Notification();
    }

    saveNotification() {
        this.notification.userId = this.currentUser.personCode;
        this.notification.createdBy = this.currentUser.name;
        // this.notification.fromDate = new Date(this.notification.fromDate);
        // this.notification.toDate = new Date(this.notification.toDate);
        this.notifications.push(this.notification);
        this.spinner.show();
        this.notificationService.saveNotification(this.notification).subscribe(result => {
            this.spinner.hide();
            this.getNotifications();
        });
    }

    edit(data: Notification) {
        this.notification = new Notification(data);
    }

    editNotification(data: Notification) {
        this.spinner.show();
        this.notificationService.updateNotification(this.notification).subscribe(result => {
            this.spinner.hide();
            this.getNotifications();
        });
     }

    deleteNotification(notificationId: number) {
        this.spinner.show();
        this.notificationService.deleteNotification(notificationId).subscribe(result => {
            this.spinner.hide();
            this.getNotifications();
        });
    }

    selectedDateRange(dateRange:any) {
        if(dateRange){
            if(dateRange.startDate && dateRange.startDate._d){
                this.notification.fromDate = new Date(dateRange.startDate._d);
            }
            if(dateRange.endDate && dateRange.endDate._d){
                this.notification.toDate = new Date(dateRange.endDate._d);
            }
        }
    }

    selectedDate(dateRange:any) {
        if(dateRange){
            if(dateRange.startDate && dateRange.startDate._d){
                this.notification.date = new Date(dateRange.startDate._d);
            }
        }
    }
}