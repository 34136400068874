import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { JWTTokenService } from '../services/JWTTokenService';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService, private router: Router, private jwtTokenService: JWTTokenService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     
        const token = this.jwtTokenService.getToken();
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.spinner.hide();
               
                if (error && error.status === 404) {
                console.log('data not found');
                } else if (error && error.status === 500) {
                    console.log('Internal server error');
                } else if (error && error.status === 401) {
                    console.log('Access denied');
                    this.jwtTokenService.clearAll();
                    //this.router.navigate(['authenticate']);
                } 
                else {
                    console.log('Something went wrong');
                }
                return throwError(error);
            }));
    }
}