import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { JWTTokenService } from './JWTTokenService';

const API_URL = environment.apiUrl + '/account';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  language: BehaviorSubject<string>;
  constructor(private http: HttpClient, private jwkTokenService: JWTTokenService) {
    this.language = new BehaviorSubject('');
  }

  setLanguage(language: string) {
    this.language.next(language);
  }

  setAcessToken(): Observable<string> {
    return this.http.get(`${API_URL}/accessToken`).pipe(
      map((response: any) => {
        return response;
      }));
  }

}