import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.css']
})
export class AaccessdeniedComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
