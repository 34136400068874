import { OnInit, Component, QueryList, ViewChildren } from '@angular/core';

import { ReportService } from '../../services/report.service';
import { AccessedReport } from '../../models/accessedreport';
import { NgbdSortableHeader, SortEvent } from '../../comman/directive/sorttable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdUserService } from 'src/app/services/aduser.service';
import { AdUser } from 'src/app/models/aduser';

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'azure-ad-user',
  templateUrl: './azure-ad-user.component.html',
  styleUrls: ['./azure-ad-user.component.css']
})
export class AzureAdUserComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  userList: AccessedReport[];
  user: AdUser;
  users: AdUser[];
  page = 1;
  pageSize = 4;
  collectionSize = 0;
  userPrincipalName: string = "";
  filterUser: string = "";

  constructor(private reportService: ReportService, private spinner: NgxSpinnerService, private userService: AdUserService) {
    this.user = new AdUser();
  }

  ngOnInit() {
    this.getAzureAdGroupMembers();
  }

  getUserByUserPrincipalName() {
    this.spinner.show();
    this.userService.getUserByUserPrincipalName().subscribe(user => {
      this.spinner.hide();
     // this.user = user;
    }, error => {
      this.spinner.hide();
      this.user = new AdUser();
    });
  }

  clear() {
    this.userPrincipalName = "";
    this.user = new AdUser();
  }

  private getAzureAdGroupMembers() {
    this.spinner.show();
    this.userService.getAzureAdGroupMembers().subscribe(users => {
      this.spinner.hide();
      this.users =[];
      this.users = users;
      this.collectionSize = this.users.length;
    });
  }

  addUser() {
    this.spinner.show();
    if(!this.checkUserExist(this.user.userPrincipalName)){
      this.userService.addUser(this.user.userPrincipalName).subscribe(response => {
        this.spinner.hide();
        this.getAzureAdGroupMembers();
        alert('Assigned successfully!');
      });
    }
    else{
      this.spinner.hide();
      alert('User already assigned!');
    }
   
  }

  checkUserExist(userPrincipalName: string): boolean{
   return this.users.map(function(e) { return e.userPrincipalName; }).indexOf(userPrincipalName) > -1;
  }

  removeUser(userPrincipalName: string) {
    if (confirm("Are you sure? to remove " + userPrincipalName + " from IB Insights group!")) {
    this.spinner.show();
      this.userService.deleteUser(userPrincipalName).subscribe(resonse => {
        this.spinner.hide();
        this.getAzureAdGroupMembers();
        alert('Deleted successfully!');
      });
    }


  }



  get usersList(): AdUser[] {
    return this.users && this.users.length > 0 ? this.users.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize) : [];
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting users
    if (direction === '' || column === '') {
      this.users;
    } else {
      this.users = this.users.sort((a, b) => {
        const res = compare(`${a[column]}`, `${b[column]}`);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}