import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE } from '../../models/language';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { AppService } from '../../services/app.service';
import { HelperService, NavBar } from '../../comman/helper.service';
import { AuthorizationService} from '../../services/authorization.service';
import {Router} from '@angular/router';
import { DebugRenderer2 } from '@angular/core/src/view/services';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navBar: NavBar;
  user: User;
  iboUrl: string = '';
  selectedLanguage = '';
  public languageOptions = [];

  constructor(private appService: AppService, private helperService: HelperService, private translate: TranslateService, private userService: UserService, private confirmDialogService: ConfirmDialogService,
    private appSerivce: AppService, private authorizationService:AuthorizationService,private router:Router) {
    let lang = localStorage.getItem('currentLang');
    lang = lang ? lang : 'en';
    this.translate.use(lang);
    //translate.setDefaultLang('en');
    this.user = new User();
    this.navBar = { iboUrl: '', ibisUrl: '', myibUrl: '', storeUrl: '', blogsUrl: '', askQuestionUrl: '' };
    this.languageOptions = LANGUAGE;
    
  }

  ngOnInit() {
    let self = this;
     this.appService.language.subscribe(response => {
      self.selectedLanguage = response;
      let data = this.languageOptions.filter(language => language.name == self.selectedLanguage)[0];
      if (data) {
        let language = data.id;
        self.switchLanguage(language);
      }
      this.user = this.userService.getUser();
      this.user = this.user ? new User(this.user) : new User();
      let getLang = localStorage.getItem('currentLang');
      if (getLang) {
        let currentLang = this.languageOptions.filter(language => language.id == getLang)[0].name;
        self.selectedLanguage = self.selectedLanguage ? self.selectedLanguage : currentLang;
      }
      else {
        self.selectedLanguage = this.user.preferredLanguage;
      }

    });

    
  }


  showDialog(currentLanguage: string) {
    
    let lang = this.languageOptions.filter(language => language.name == currentLanguage)[0].id;
    // this.translate.use(lang);
    localStorage.setItem('currentLang', lang);
    this.appService.setLanguage(currentLanguage);
    let self = this;
    let message = `Are you sure you want to select ${currentLanguage} as your default language the next time you login to IB Insights?
    This will not change your language settings for any other appliation.`;
    let isExternalUser = this.userService.getUserType();
    //let isExternalUser = 'externalUser';
    if (isExternalUser == 'externalUser') {
      this.confirmDialogService.confirmThis("YESSAVE", "CANCEL", "SAVELANGUAGEPREFERENCE", message, function () {
       
        self.userService.savePreferredLanguage(self.selectedLanguage).subscribe(result => {
          self.userService.getUserById().subscribe(user => {
            self.userService.setUser(user);
          });
        });
      }, function () {

      });
    } 
    }

  switchLanguage(language: string) {
    this.navBar = this.helperService.navBarUrl(language);
    this.translate.use(language);
  }

  signOut() {
    this.user = new User();
    let isExternal = this.helperService.isExternalUser();
    this.authorizationService.signOut();
    if(isExternal){
    window.location.href = environment.myIBUrl;  
    }
    else{
      window.location.href = environment.ibConnectUrl;  
    }
 }
}
