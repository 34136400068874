import { OnInit, Component, ViewChild, ElementRef } from '@angular/core';
import {
  HttpErrorResponse
} from '@angular/common/http';
import { User } from '../../models/user';
import { School } from '../../models/school';
import { Programme } from '../../models/programme';
import { Notification } from '../../models/notification';

import { AccessedReport } from '../../models/accessedreport';
import { ReportService } from '../../services/report.service';
import { EmbedConfig } from 'src/app/models/embedconfig';
import * as pbi from 'powerbi-client';
import { TokenType } from 'src/app/models/tokentype';
import { ReportPdf } from 'src/app/models/reportpdf';

import { UserService } from '../../services/user.service';
import { SchoolService } from '../../services/school.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { ToastrService } from 'ngx-toastr';
import { LANGUAGE } from 'src/app/models/language';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewedNotification } from 'src/app/models/viewednotification';
import { IbIsUser } from 'src/app/models/ibisuser';
import { HelperService, TermsAndPolicy } from 'src/app/comman/helper.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UserInfo } from 'src/app/models/userinfo';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { JWTTokenService } from 'src/app/services/JWTTokenService';
import { environment } from '../../../environments/environment';
import { AdUserService } from 'src/app/services/aduser.service';
import { LoggingService } from 'src/app/services/logging.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

var BookmarkShowcaseState = {
  bookmarksReport: null
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('videoPlayer') myVideo: ElementRef;
  public screenHeight: number;
  homeForm: FormGroup;
  userInfo: UserInfo;
  schoolName: string = '';
  languageType = '';
  personCode = '';
  schoolCode = '';
  programmeAbbr = '';
  user: User;
  isChecked = false;

  @ViewChild('reportContainer') reportContainer: ElementRef;
  embedConfig: EmbedConfig;
  selectedReportType = '';
  userReport: AccessedReport;
  ibIsUser: IbIsUser;
  showHideBaner: boolean = true;
  schoolList: School[];
  private languages = [];
  programmeList: Programme[];
  notificationList: Notification[];
  isExternalUser: boolean;
  viewedNotification: ViewedNotification[];
  myp = false;
  dp = false;
  cp = false;
  ett = false;
  grb = false;
  ettTab = false;
  grbTab = false;
  reportPdf: ReportPdf;
  termsAndPolicy: TermsAndPolicy;
  videoUrl: string = '';

  resultFormatter = (result: School) => result.schoolName;
  inputFormatter = (result: School) => result.schoolName;

  constructor(private reportService: ReportService,private adService: AdUserService, private notificationService: NotificationService, private appService: AppService, private helperService: HelperService,
    private userService: UserService, private schoolService: SchoolService, private authorizationService: AuthorizationService,
    private activatedRoute: ActivatedRoute, private programmeService: ProgrammeService, private toastr: ToastrService,
    private jwtTokenService: JWTTokenService, private router: Router, private toaster: ToastrService, private spinner: NgxSpinnerService, private loggingService: LoggingService) {

    this.reportPdf = new ReportPdf();
    this.embedConfig = new EmbedConfig();
    this.languages = LANGUAGE;
    this.termsAndPolicy = { termsAndConditionsUrl: '', iBprivacyPolicyUrl: '' }
    this.homeForm = new FormGroup({
      schoolObj: new FormControl(''),
      school: new FormControl('')
    });

    this.userReport = new AccessedReport();
  }

  ngOnInit() {
    this.loggingService.logPageView('Home',this.router.url);
    this.screenHeight = (window.screen.height);
    this.appService.language.subscribe(response => {
      let data = this.languages.filter(language => language.name == response)[0];
      if (data) {
        this.languageType = data.id;
        this.videoUrl = this.helperService.getVideoUrl(this.languageType);
        this.termsAndPolicy = this.helperService.getTermsPolicy(this.languageType);
      }

      if(this.ett) {
        this.resetPowerBiReport();
        this.getToken(this.schoolCode, "TIMETABLE");
      }
      else if(this.grb) {
        this.resetPowerBiReport();
        this.getToken(this.schoolCode, "GRADEBOUNDARY");
      }

    });
    // if (!this.jwtTokenService.isTokenExpired) {
    this.userInfo = this.jwtTokenService.getUser();
    if (this.userInfo && this.userInfo.email) {
      this.isExternalUser = this.checkUserType(this.userInfo.email);
      if (this.isExternalUser) {
        if (this.userInfo.email) {
          this.getUserByPersonCode();//
        }
        else {
          alert('user not available!');
          this.jwtTokenService.clearAll();
          this.router.navigate(['accessdenied']);
        }
      }
      else {
        if (this.userInfo.userPrincipalName) {
          this.getUserByUPN();
        }
      }
    }
  }

  getUserByUPN() {
    let self = this;
    this.spinner.show();
    this.adService.getUserByUserPrincipalName().subscribe(user => {
      self.user = user;
      if (self.user) {
        // self.user.name = user.name;
        self.user.preferredLanguage = self.user.preferredLanguage ? self.user.preferredLanguage : 'English';
        // this.getSchoolListByUserId();
        self.userService.setUser(self.user);
        let data = this.languages.filter(language => language.name == self.user.preferredLanguage)[0];
        if (data) {
          self.languageType = data.id;
          let lang = localStorage.getItem('currentLang');
          if (!lang) {
            localStorage.setItem('currentLang', data.id);
            self.appService.setLanguage(data.name);
          }
          else {
            let langName = this.languages.filter(language => language.id == lang)[0];
            self.appService.setLanguage(langName.name);
          }
        }

        this.schoolService.getSchoolListByUserId().subscribe(schools => {
          this.spinner.hide();
          this.schoolList = schools;
          if (this.schoolList && this.schoolList.length > 0) {
            this.schoolCode = sessionStorage.getItem("schoolCode");
            this.schoolCode = this.schoolCode ? this.schoolCode : this.schoolList[0].schoolCode;
            this.getProgrammeListByUserIdSchoolCode(this.schoolCode);
            let data = this.schoolList.filter(school => school.schoolCode == this.schoolCode);
            if (data) {
              let school = data[0];
              this.schoolName = data[0] && data[0].schoolName ? data[0].schoolName : '';
              self.homeForm.get('schoolObj').setValue(school);
            }
          }
        });
      }
      else {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['accessdenied']);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 401 || error.status == 404) {
        this.jwtTokenService.clearAll();
        this.router.navigate(['accessdenied']);
      }
    });

  }

  getUserByPersonCode() {
    this.spinner.show();
    let self = this;
    this.userService.getUserById().subscribe(user => {
      self.spinner.hide();
      self.user = user;
      self.schoolCode = self.user.schoolCode;
      self.programmeAbbr = self.user.programme;
      this.checkCurrentSelectedProgramme(self.programmeAbbr);
      if (self.user) {
        self.user.preferredLanguage = self.user.preferredLanguage ? self.user.preferredLanguage : 'English';
        // self.getNotifications(self.user.personCode);
        self.getSchoolListByUserId();
        console.log("216: in home -> gUBPC - >getSLBUI : " + JSON.stringify(self.getSchoolListByUserId()))
        self.getProgrammeListByUserIdSchoolCode(self.user.schoolCode);
        self.userService.setUser(self.user);
        let data = this.languages.filter(language => language.name == self.user.preferredLanguage)[0];
        if (data) {
          self.languageType = data.id;
          let lang = localStorage.getItem('currentLang');
          if (!lang) {
            localStorage.setItem('currentLang', data.id);
            self.appService.setLanguage(data.name);
          }
          else {
            let langName = this.languages.filter(language => language.id == lang)[0];
            self.appService.setLanguage(langName.name);
          }
        }
        if (!self.user.isAgree) {
          self.showTermsOfServices();
        }
      }
    });
  }

  GetURLParameter(sParam) {
    var mainURL = document.location + '';
    if (mainURL) {
      if (mainURL.indexOf('#') > -1) {
        var pageUrls = mainURL.split('#');
        var sURLVariables = pageUrls[1].split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
          var sParameterName = sURLVariables[i].split('=');
          if (sParameterName[0] == sParam) {
            return sParameterName[1];
          }
        }
      }
    }
  }

  private toUnicode(email) {
    let result = email;
    let isPlus = email.indexOf('+') > -1;
    if (isPlus) {
      let data = email.split('+');
      result = data[0] + this.convertToUnicode('+') + data[1];
    }
    let isMinus = result.indexOf('-') > -1;
    if (isMinus) {
      let data = result.split('-');
      result = data[0] + this.convertToUnicode('-') + data[1];
    }
    return result;
  };

  private convertToUnicode(str) {
    let result = "";
    for (let i = 0; i < str.length; i++) {
      // Assumption: all characters are < 0xffff
      result += "\\u" + ("000" + str[i].charCodeAt(0).toString(16)).substr(-4);
    }
    return result;
  }

  getSchoolListByUserId() {
    let self = this;
    this.schoolService.getSchoolListByUserId().subscribe(schools => {
      this.schoolList = schools;
      // console.log("283: in home -> getSLBUI => schools: " + JSON.stringify(schools))
      if (this.schoolList && this.schoolList.length > 0) {
        this.schoolCode = sessionStorage.getItem("schoolCode");
        this.schoolCode = this.schoolCode ? this.schoolCode : this.user.schoolCode;
        let data = this.schoolList.filter(school => school.schoolCode == this.schoolCode);
        if (data) {
          let school = data[0];
          this.schoolName = data[0] && data[0].schoolName ? data[0].schoolName : '';
          self.homeForm.get('schoolObj').setValue(school);
        }
      }
    });
  }

  acccetTermsOfServices() {
    this.user.isAgree = true;
    this.userService.updateUser(this.user).subscribe(response => {
      this.userService.getUserById().subscribe(user => {
        this.user = user;
        if (this.user) {
          this.userService.setUser(this.user);
        }
      });
    });
  }

  getProgrammeListByUserIdSchoolCode(schoolCode: string) {
    this.programmeList = [];
    this.spinner.show();
    this.programmeService.getProgrammeListByUserIdSchoolId(schoolCode).subscribe(programmes => {
      this.spinner.hide();
      this.programmeList = programmes;
      if (this.programmeList && this.programmeList.length > 0) {
        this.programmeAbbr = this.programmeList[0].abbr;
        this.checkCurrentSelectedProgramme(this.programmeAbbr);

        if(this.programmeList.some(x => x.abbr == 'MYP') && this.programmeList.length == 1) {
          this.ettTab = false;  //if the school doesn't have programme CP or DP, in this case should not display Exam Timetable Tab
          this.grbTab = false;
        } else {
          this.ettTab = true;
          this.grbTab = true;
        }
      }
    });
  }

  getNotifications(personCode: string) {
    this.notificationService.getActiveNotificaitons().subscribe(notifications => {
      this.notificationList = notifications;
      if (this.notificationList.length > 0) {
        for (let notification of this.notificationList) {
          this.toaster.show(`${notification.description}:${notification.date}`, "Notification", {
            timeOut: 1000000,
            tapToDismiss: true,
            closeButton: true
          });
        }
        let viewedNotification = this.getViewedNotifications(this.notificationList);
        if (viewedNotification.length > 0) {
          setTimeout(() => {
            this.saveViewedNotification(viewedNotification);
          }, 5000);
        }
      }
    });
  }

  saveViewedNotification(viewedNotification: ViewedNotification[]) {
    this.notificationService.saveViewedNotification(viewedNotification).subscribe(response => {
    });
  }

  saveUser() {
    this.userService.saveUser(this.user).subscribe(response => {
    });
  }

  updateUser() {
    this.userService.updateUser(this.user).subscribe(response => {
    });
  }

  showTermsOfServices() {
    let element: HTMLElement = document.getElementById('termsService') as HTMLElement;
    element.click();
  }

  programmeLogo(programmeAbbr: string, programmeId: string) {
    this.checkCurrentSelectedProgramme(programmeAbbr);
    this.programmeAbbr = programmeAbbr;
    sessionStorage.setItem("programmeAbbr", this.programmeAbbr);
    this.grb = false;
    this.ett = false
  }

  gotoReport(reportTypeId: number) {
    let _schoolCode = this.schoolCode;
    let _programmeAbbr = this.programmeAbbr;
    let _reportType = reportTypeId;
    this.router.navigate(['/report'], { queryParams: { schoolCode: _schoolCode, programme: _programmeAbbr, reportType: _reportType } });
  }

  showSchoolDropdown() {
    return this.schoolList ? (this.schoolList.length > 1 && this.schoolList.length < 3 ? true : false) : false;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? [] : this.schoolList.filter(school => school.schoolName.toLowerCase().startsWith(term.toLocaleLowerCase())).splice(0, 10)))

  selectSchool(data: any) {
    if (this.schoolList && this.schoolList.length > 0) {
      let schools = this.schoolList.filter(school => school.schoolName === data.item.schoolName);
      if (schools) {
        this.schoolCode = schools[0].schoolCode;
        this.getProgrammeListByUserIdSchoolCode(this.schoolCode);
        sessionStorage.setItem("schoolCode", this.schoolCode);
      }
    }
  }

  enableMenuReport(reportType: string) {
    this.myp = false;
    this.dp = false;
    this.cp = false;
    this.ett = false;
    this.grb = false;

    if(reportType.toUpperCase()=='TIMETABLE'){
      this.ett = true;
      this.grb = false;
    }
    else if(reportType.toUpperCase()=='GRADEBOUNDARY'){
      this.grb = true;
      this.ett = false;
    }
    else{
      this.ett = false;
      this.grb = false;
    }

      this.getToken(this.schoolCode, reportType);  //SUBJECTQUESTIONANALYSIS
  }


  getToken(schoolCode: string,  selectedReportType: string) {
    // console.log("schoolCode:" +schoolCode+ " and selectedReportType: "+selectedReportType);
    this.languageType = this.languageType ? this.languageType : 'en';
    this.spinner.show();
    this.reportService.getEmbeddedToken(selectedReportType, this.languageType).subscribe(embedConfig => {
        // console.log("embedconfig: " + JSON.stringify(embedConfig));
        this.spinner.hide();
        this.embedConfig = embedConfig;
        this.resetPowerBiReport();
        this.showReport(embedConfig, schoolCode, selectedReportType);
        this.userReport.userId = this.personCode;
        this.userReport.school = schoolCode;
        this.userReport.reportName = selectedReportType;
        this.saveUserReportDetail(this.userReport);
    });
    // console.log('selectedReportType: ' + selectedReportType);
}



public saveUserReportDetail(userReport: AccessedReport) {
  this.reportService.saveUserReportDetails(userReport).subscribe(response => {
  });
}

resetPowerBiReport() {
  let reportContainer = this.reportContainer.nativeElement;
  let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  powerbi.reset(reportContainer);
}

private showReport(embedConfig: EmbedConfig, schoolCode: string, selectedReportType: string) {

  let embedUrl = this.AddParameters(embedConfig.embedUrl, schoolCode, selectedReportType);
  let embedReportId = embedConfig.id; //POWERBI.REPORT_ID;

  let config: any = {
      type: 'report',
      tokenType: TokenType.Embed,
      accessToken: embedConfig.embeddedToken,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {
          filterPaneEnabled: false
      }
  };


  // Get a reference to the embedded report HTML element
  let reportContainer = this.reportContainer.nativeElement;

  let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

  // Embed the report and display it within the div container
  BookmarkShowcaseState.bookmarksReport = powerbi.embed(reportContainer, config);

  BookmarkShowcaseState.bookmarksReport.on("error", () => {
      // this.getToken(this.selectedreporttype);
  });
  BookmarkShowcaseState.bookmarksReport.on("rendered", () => {


  });
}

AddParameters(embedUrl: string, schoolCode: string, selectedReportType: string): string {
  let selectedReport = selectedReportType.toUpperCase() == 'GRADEBOUNDARY' ? 'CombinedBoundaries' : 'ExamSchedule'
  let embedUrlWithParameter = `${embedUrl}&$filter=${selectedReport}/SchoolCode eq ${this.schoolCode}&ctid=68b2d50a-57dd-4bd5-85bb-a249b0b19ddf`;
  // console.log("embedUrlWithParameter==>", embedUrlWithParameter);
  return embedUrlWithParameter;
}

filterParameter() {
  let filterValue = `School/Code eq ${this.schoolCode}`;
  return filterValue;
}

downloadTimeTable() {
  //this.reportPdf.bookmarkState = "";
  this.reportPdf.reportType = this.selectedReportType;
  this.reportPdf.language = this.languageType;
  this.reportPdf.filters = this.filterParameter();
  this.spinner.show();
  this.reportService.getReportAsPdf(this.reportPdf).subscribe(response => {
      this.spinner.hide();
      var file = new Blob([response], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(file);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.selectedReportType + ".pdf";
      link.click();
  });
}

checkCurrentSelectedProgramme(programme: string) {
  let _programme = sessionStorage.getItem("programmeAbbr");
  programme = programme ? programme : _programme;
  this.programmeAbbr = programme;

  this.myp = false;
  this.dp = false;
  this.cp = false;
  this.ett = false;
  this.grb = false;

  if (programme == 'MYP') {
    this.myp = true;
    this.dp = false;
    this.cp = false;
    this.ett = false;
    this.grb = false;
  }
  else if (programme == ('DP' || 'DIPLOMA')) {
    this.dp = true;
    this.myp = false;
    this.cp = false;
    this.ett = false;
    this.grb = false;
  }
  else if (programme == 'CP') {
    this.cp = true;
    this.myp = false;
    this.dp = false;
    this.ett = false;
    this.grb = false;
  }
  else{
    this.myp = false;
    this.dp = false;
    this.cp = false;
    this.ett = false;
    this.grb = false;
  }
}

  getViewedNotifications(notifications: Notification[]) {
    this.viewedNotification = [];
    for (let notification in notifications) {
      let data = new ViewedNotification();
      data.notificationId = notifications[notification].id;
      data.userId = notifications[notification].userId;
      data.notificationDate = new Date();
      this.viewedNotification.push(data);
    }
    return this.viewedNotification;
  }

  checkSchoolListLength() {
    let data = this.schoolList && (this.schoolList.length > 0 && this.schoolList.length <= 10) ? true : false;
    return data;
  }

  getAllSchool() {
    this.schoolService.getAllSchool().subscribe(schools => {
      this.schoolList = schools;
      if (this.schoolList && this.schoolList.length > 0) {
        this.programmeService.getProgrammeListBySchoolId(this.schoolList[0].schoolCode).subscribe(programmes => {
          this.programmeList = programmes;
        });
        //     this.schoolCode = sessionStorage.getItem("schoolCode");
        //     this.schoolCode = this.schoolCode ? this.schoolCode : this.user.schoolCode;
        //     let data = this.schoolList.filter(school => school.schoolCode == this.schoolCode);
        //     if (data) {
        //       this.schoolName = data[0].schoolName;
        //     }
      }
    });
  }

  public checkUserType(emailId: string): boolean {
    let data = emailId.split('@')[1];
    if (data === environment.userTypeDomain) {
      this.userService.setUserType('internalUser');
      return false;
    }
    else {
      this.userService.setUserType('externalUser');
      return true;
    }
  }

  startVideo() {
    this.myVideo.nativeElement.play();
  }

  stopVideo() {
    this.myVideo.nativeElement.pause();
  }

  resetVideo() {
    this.myVideo.nativeElement.pause();
    let video: any = document.getElementById("videoId");
    video.currentTime = 0;
  }
}
