import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    private subject = new Subject<any>();

    constructor() { }

    confirmThis(btnFirst: string, btnSecond: string, title: string, message: string, siFn: () => void, noFn: () => void) {
        this.setConfirmation(btnFirst, btnSecond, title, message, siFn, noFn);
    }

    setConfirmation(btnFirst: string, btnSecond: string, title: string, message: string, siFn: () => void, noFn: () => void) {
        let that = this;
        this.subject.next({
            type: "confirm",
            btnFirst: btnFirst,
            btnSecond: btnSecond,
            title: title,
            text: message,
            siFn:
                function () {
                    that.subject.next(); //this will close the modal  
                    siFn();
                },
            noFn: function () {
                that.subject.next();
                noFn();
            }
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}  