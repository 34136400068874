import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { School } from "../models/school";
import { User } from "../models/user";
import { SchoolService } from "../services/school.service";
import { UserService } from "../services/user.service";

@Injectable({ providedIn: 'root' })
export class SchoolResolver implements Resolve<any> {
    
  currentUser: User;
  constructor(private schoolService: SchoolService) {}

  resolve(): Observable<any>|Promise<any>|any {
   return  this.schoolService.getSchoolListByUserId();
  }
}