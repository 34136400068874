import { Directive, HostListener, Input } from '@angular/core';
import { ExportService } from '../services/export.service';

@Directive({
  selector: '[excelExport]'
})
export class ExportDirective {

  constructor(private exportService: ExportService) { }

  @Input('excelExport') list: any[];
  @Input() fileName: string;

  @HostListener('click', ['$event']) onClick($event) {
    this.exportService.exportExcel(this.list, this.fileName);
  }
}