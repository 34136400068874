export class School {
    id: number;
    personCode: string;
    schoolCode: string;
    schoolName: string;
    schoolState: string;
    schoolCountry: string;
    geographicalRegion:string;
    regionalComposition:string;
    legalStatus:string;

    constructor(private school?: School) {
        school && school.id ? this.id = school.id : this.id = 0;
        school && school.personCode ? this.personCode = school.personCode : this.personCode = null;
        school && school.schoolCode ? this.schoolCode = school.schoolCode : this.schoolCode = null;
        school && school.schoolName ? this.schoolName = school.schoolName : this.schoolName = null;
        school && school.schoolState ? this.schoolState = school.schoolState : this.schoolState = '';
        school && school.schoolCountry ? this.schoolCountry = school.schoolCountry : this.schoolCountry = null;
        school && school.geographicalRegion ? this.geographicalRegion = school.geographicalRegion : this.geographicalRegion = null;
        school && school.regionalComposition ? this.regionalComposition = school.regionalComposition : this.regionalComposition = null;
        school && school.legalStatus ? this.legalStatus = school.legalStatus : this.legalStatus = null;
    }
}