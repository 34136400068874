import { Component, OnInit, ViewChild, ElementRef, ComponentRef, OnDestroy } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as pbi from 'powerbi-client';

import { AccessedReport } from '../../models/accessedreport';
import { ReportService } from '../../services/report.service';
import { ReportType } from '../../models/reporttype';
import { SchoolService } from 'src/app/services/school.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { School } from 'src/app/models/school';
import { Programme } from 'src/app/models/programme';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmbedConfig } from 'src/app/models/embedconfig';
import { TokenType } from 'src/app/models/tokentype';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router"
import { Location } from "@angular/common";
import { AppService } from 'src/app/services/app.service';
import { LANGUAGE } from 'src/app/models/language';
import { HelperService } from 'src/app/comman/helper.service';
import { ReportPdf } from 'src/app/models/reportpdf';
import { filter } from 'rxjs/operators';
import { LoggingService } from 'src/app/services/logging.service';
var BookmarkShowcaseState = {
    bookmarksReport: null
}

const MINUTES_BEFORE_EXPIRATION = 10;
const INTERVAL_TIME = 4;
// Get the token expiration from the access token
var tokenExpiration;



@Component({
    selector: 'report-list',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnDestroy {
    public screenHeight: number;
    ref: ComponentRef<any>;
    @ViewChild('reportContainer') reportContainer: ElementRef;

    public reportType = ReportType;
    reportPdf: ReportPdf;
    private languages = [];
    currentSchool: School;
    selectedReportType = '';
    selectedSchool = '';
    schoolCode = '';
    selectedProgramme = '';
    public reportTypeOptions = [];
    userReport: AccessedReport;
    embedConfig: EmbedConfig;
    reportTypeId: number = 0;
    currentUser: User;
    currentLang: string = '';
    schoolList: School[];
    programmeList: Programme[];
    isFirstLoad: boolean = true;
    programmeCategory = { 'programme': '', 'category': '' };
    id: any;

    constructor(private reportService: ReportService, private schoolService: SchoolService, private spinner: NgxSpinnerService, private router: Router, private readonly location: Location, private loggingService: LoggingService,
        private programmeService: ProgrammeService, private helperService: HelperService, private appService: AppService, private userService: UserService, private activatedRoute: ActivatedRoute, private translate: TranslateService) {
        this.userReport = new AccessedReport();
        this.currentSchool = new School();
        this.languages = LANGUAGE;
        this.embedConfig = new EmbedConfig();
        this.currentUser = new User();
        this.reportPdf = new ReportPdf();
        this.reportTypeOptions = Object.keys(this.reportType);
        this.currentUser = this.userService.getUser();
        // this.getSchoolListByUserId(this.currentUser.personCode);

    }

    ngOnInit() {
        this.loggingService.logPageView('Report', this.router.url);
        this.screenHeight = (window.screen.height);
        this.activatedRoute.queryParams.subscribe(params => {
            this.userReport.school = params['schoolCode'];
            this.schoolCode = this.userReport.school;
            this.selectedProgramme = params['programme'];
            // this.reportTypeId = +params['reportType'];	
            this.reportTypeId = params['reportType'];
            this.selectedReportType = this.reportTypeOptions[this.reportTypeId - 1];
            this.userReport.reportName = this.selectedReportType;
            let data = this.activatedRoute.snapshot.data;
            this.schoolList = data.school;
            if (this.schoolList && this.schoolList.length > 0) {
                this.currentSchool = this.schoolList.filter(school => school.schoolCode == this.schoolCode)[0];
            }
            this.selectedSchool = this.currentSchool.schoolName;

            this.getProgrammeListByUserIdSchoolCode(this.userReport.school, false);
            this.userReport.userId = this.currentUser.personCode;
            this.userReport.emailId = this.currentUser.emailId;
            this.userReport.role = this.currentUser.role;
            this.userReport.userName = this.currentUser.name;
            let lang = localStorage.getItem('currentLang');
            if (lang) {
                this.currentLang = lang;
            } else {
                this.currentLang = this.getLanguageId(this.currentUser.preferredLanguage);
            }
            this.getToken(this.schoolCode, this.selectedProgramme, this.selectedReportType);

        });

        this.appService.language.subscribe(response => {
            this.resetPowerBiReport();
            if (response) {
                this.currentLang = this.getLanguageId(response);
            }
            if (!this.isFirstLoad) {
                this.getToken(this.schoolCode, this.selectedProgramme, this.selectedReportType);
            }
            this.isFirstLoad = false;
        });

        // Set an interval to check the access token expiration, and update if needed
        this.id = setInterval(() => this.checkTokenAndUpdate(), INTERVAL_TIME * 60 * 1000);

    }

    ngOnDestroy() {
        if (this.id) {
            clearInterval(this.id);
        }
    }


    exportReportAsPDF(bookmarkState: string) {
        this.reportPdf.bookmarkState = bookmarkState;
        this.reportPdf.reportType = this.selectedReportType;
        this.reportPdf.language = this.currentLang;
        this.reportPdf.filters = this.filterParameter();
        this.spinner.show();
        this.reportService.getReportAsPdf(this.reportPdf).subscribe(response => {
            this.spinner.hide();
            var file = new Blob([response], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = this.selectedReportType + ".pdf";
            link.click();
        });
    }

    getLanguageId(language: string): string {
        let lang = '';
        let data = this.languages.filter(x => x.name === language)[0];
        if (data) {
            lang = data.id;
        }
        return lang ? lang : 'en';
    }

    reloadReport(schoolCode: string, programme: string, selectedReportType: string) {
        // this.resetPowerBiReport();
        let reportTypeId = this.reportTypeOptions.findIndex(x => x === selectedReportType);
        let _schoolCode = schoolCode;
        let _programme = programme;
        let _reportTypeId = reportTypeId + 1;
        const queryParams: Params = { schoolCode: _schoolCode, programme: _programme, reportType: _reportTypeId };
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                replaceUrl: true,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }

    getToken(schoolCode: string, programme: string, selectedReportType: string) {
        this.currentLang = this.currentLang ? this.currentLang : 'en';
        this.spinner.show();
        this.reportService.getEmbeddedToken(selectedReportType, this.currentLang).subscribe(embedConfig => {
            this.spinner.hide();
            this.embedConfig = embedConfig;
            this.resetPowerBiReport();
            this.showReport(embedConfig, schoolCode, programme);
            this.userReport.school = schoolCode;
            this.userReport.reportName = selectedReportType;
            this.saveUserReportDetail(this.userReport);
        });
    }

    private showReport(embedConfig: EmbedConfig, schoolCode: string, programme: string) {

        let embedUrl = this.AddParameters(embedConfig.embedUrl, programme, schoolCode);
        let embedReportId = embedConfig.id; //POWERBI.REPORT_ID;  

        let config: any = {
            type: 'report',
            tokenType: TokenType.Embed,
            accessToken: embedConfig.embeddedToken,
            embedUrl: embedUrl,
            id: embedReportId,
            settings: {
                filterPaneEnabled: false
                // navContentPaneEnabled: true
            }//,
            //slicers: slicers,
            //  filters: [filter, filterp]  
        };


        // Get a reference to the embedded report HTML element	
        let reportContainer = this.reportContainer.nativeElement;

        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

        // Modified by:balajit
        //code to reset report container

        // powerbi.reset(reportContainer);

        // Embed the report and display it within the div container	
        BookmarkShowcaseState.bookmarksReport = powerbi.embed(reportContainer, config);

        BookmarkShowcaseState.bookmarksReport.on("error", () => {
            // this.getToken(this.selectedreporttype);
        });
        BookmarkShowcaseState.bookmarksReport.on("rendered", () => {


        });
    }
    // Capture new bookmark of the current state and update the bookmarks list	
    onBookmarkCaptureClicked() {
        let self = this;
        // Capture the report's current state	
        BookmarkShowcaseState.bookmarksReport.bookmarksManager.capture({allPages: true,personalizeVisuals: true}).then(function (capturedBookmark) {
            self.exportReportAsPDF(capturedBookmark.state);
        });
    }

    resetPowerBiReport() {
        let reportContainer = this.reportContainer.nativeElement;
        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        powerbi.reset(reportContainer);
    }

    public getSchoolListByUserId(personCode: string) {
        this.spinner.show();
        this.schoolService.getSchoolListByUserId().subscribe(schools => {
            this.spinner.hide();
            this.schoolList = schools;
            if (this.schoolList && this.schoolList.length > 0) {
                this.currentSchool = this.schoolList.filter(school => school.schoolCode == this.schoolCode)[0];
            }

            this.selectedSchool = this.currentSchool.schoolName;
        });
    }

    public getProgrammeListByUserIdSchoolCode(schoolId: string, isReload: boolean) {
        this.programmeService.getProgrammeListByUserIdSchoolId(schoolId).subscribe(programmes => {
            this.programmeList = programmes;
            debugger;
            if (this.programmeList && this.programmeList.length > 0) {
                this.selectedProgramme = this.selectedProgramme ? this.selectedProgramme : this.programmeList[0].abbr;
                if (isReload) {
                    this.reloadReport(schoolId, this.selectedProgramme, this.selectedReportType);
                }
            }
        });
    }

    public saveUserReportDetail(userReport: AccessedReport) {
        this.reportService.saveUserReportDetails(userReport).subscribe(response => {
        });
    }

    AddParameters(embedUrl: string, programme: string, schoolCode: string): string {
        this.programmeCategory = this.validateProgramme(programme);
        let embedUrlWithParameter = `${embedUrl}&$filter=Programme/Programme eq '${this.programmeCategory.programme}' and School/Code eq ${this.currentSchool.schoolCode} and School/Country eq '${this.currentSchool.schoolCountry}' and School/Legalstatus eq '${this.currentSchool.legalStatus}' and School/Region eq '${this.currentSchool.geographicalRegion}' and School/State eq '${this.currentSchool.schoolState ? this.currentSchool.schoolState : '0'}'${this.programmeCategory.category}&ctid=68b2d50a-57dd-4bd5-85bb-a249b0b19ddf`;
         return embedUrlWithParameter;
    }

    validateProgramme(value: string): any {
        this.programmeCategory.programme = '';
        this.programmeCategory.category = '';
        if (value === 'DP' || value === 'CP') {
            this.programmeCategory.programme = 'DIPLOMA';
            this.programmeCategory.category = this.validateCategory(value);
        }
        else {
            this.programmeCategory.programme = value;
            this.programmeCategory.category = '';
        }
        return this.programmeCategory;
    }

    validateCategory(programme: string): string {
        let category = '';
        if (this.selectedReportType === 'SUBJECTQUESTIONANALYSIS' && (this.currentLang === 'fr' || this.currentLang === 'sp')) {
            if (this.currentLang === 'fr') {
                if (programme === 'DP') {
                    category = ` and Category/CategoryFre in ('ANTICIPÉ', 'COURS', 'REPRISE', 'PROGRAMME DU DIPLÔME')`;
                } else if (programme === 'CP') {
                    category = ` and Category/CategoryFre in ('PROGRAMME À ORIENTATION PROFESSIONNELLE', 'PROGRAMME À ORIENTATION PROFESSIONNELLE – Anticipé', 'PROGRAMME À ORIENTATION PROFESSIONNELLE – Reprise')`;
                }
            }
            else if (this.currentLang === 'sp') {
                if (programme === 'DP') {
                    category = ` and Category/CategorySpa in ('ANTICIPADO', 'CURSO', 'DIPLOMA', 'REPETIDOR')`;
                } else if (programme === 'CP') {
                    category = ` and Category/CategorySpa in ('PROGRAMA DE ORIENTACIÓN PROFESIONAL', 'PROGRAMA DE ORIENTACIÓN PROFESIONAL: categoría Anticipado', 'PROGRAMA DE ORIENTACIÓN PROFESIONAL: repetidor')`;
                }
            }
        }
        else if (programme === 'DP') {
            category = ` and Category/Category in ('ANTICIPATED', 'COURSE', 'RETAKE', 'DIPLOMA')`;
        }
        else if (programme === 'CP') {
            category = ` and Category/Category in ('CAREER RELATED PROGRAMME','CAREER RELATED PROGRAMME - Anticipated','CAREER RELATED PROGRAMME - Retake')`;
        }
        return category;
    }

    openNav() {
        document.getElementById("mySidenav").style.width = "350px";
    }
    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }


    // embed token expiration issues 
    checkTokenAndUpdate() {
        tokenExpiration = this.embedConfig.expiration;
        let expiration = Date.parse(tokenExpiration);
        // Get the current time
        const currentTime = Date.now();
        // Time until token expiration in milliseconds
        const timeUntilExpiration = expiration - currentTime;
        const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;
        // Update the token if it is about to expired
        //  if (timeUntilExpiration <= timeToUpdate) {
        this.updateToken();
        //  }
    }

    updateToken() {
        // Generate a new embed token or refresh the user Azure AD access token
        this.reportService.getEmbeddedToken(this.selectedReportType, this.currentLang).subscribe(embedConfig => {
            this.embedConfig = embedConfig;
            let reportContainer = this.reportContainer.nativeElement;
            let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
            // Get a reference to the embedded report.
            let report = powerbi.get(reportContainer);
            // Set the new access token
            report.setAccessToken(this.embedConfig.embeddedToken);
        });
    }

    filterParameter() {
        let filterValue = `School/Code eq ${this.currentSchool.schoolCode}`;
        return filterValue;
    }

}