export const LANGUAGE = [
  {
    'id': 'en',
    'name': 'English'
  },
  {
    'id': 'fr',
    'name': 'Français'
  },
  {
    'id': 'sp',
    'name': 'Español'
  }
]