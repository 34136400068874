import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { AdUser } from '../models/aduser';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

const API_URL = environment.apiUrl + '/aduser';

@Injectable({
    providedIn: 'root',
})
export class AdUserService {
    user: AdUser;

    constructor(private http: HttpClient) {
    }

    getAzureAdGroupMembers(): Observable<AdUser[]> {
        return this.http.get(`${API_URL}`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    getUserByUserPrincipalName(): Observable<User> {
        return this.http.get(`${API_URL}`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    deleteUser(userPrincipalName: string): Observable<AdUser> {
        return this.http.delete(`${API_URL}/${userPrincipalName}`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    addUser(userPrincipalName: string): Observable<any> {
        let data = JSON.stringify({ userPrincipalName: userPrincipalName })
        return this.http.post(`${API_URL}`, data).pipe(
            map((response: any) => {
                return response;
            }));
    }
}
