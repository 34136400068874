import { Inject, Injectable } from "@angular/core";
import { JWTTokenService } from "../services/JWTTokenService";

import { environment } from '../../environments/environment';

export interface NavBar {
    iboUrl: string;
    myibUrl: string;
    ibisUrl: string;
    storeUrl: string;
    blogsUrl: string;
    askQuestionUrl: string;
}

export interface FooterLink {
    copyrightUrl: string;
    languagePolicyUrl: string;
    iBprivacyPolicyUrl: string;
    termsAndConditionsUrl: string;
}

export interface TermsAndPolicy {
    iBprivacyPolicyUrl: string;
    termsAndConditionsUrl: string;
}

export const IBOURL = {
    iboUrlEnglish: 'https://www.ibo.org/',
    iboUrlFrench: 'https://ibo.org/fr/',
    iboUrlSpanish: 'https://ibo.org/es/'
};

export const MYIBURL = {
    myIbUrlEnglish: 'https://internationalbaccalaureate.force.com/ibportal',
    myIbUrlFrench: 'https://internationalbaccalaureate.force.com/ibportal/IBPortalLogin?lang=fr',
    myIbUrlSpanish: 'https://internationalbaccalaureate.force.com/ibportal/IBPortalLogin?lang=es'
}

export const IBISURL = {
    ibisUrlEnglish: 'https://ibis.ibo.org/?language=ENGLISH',
    ibisUrlFrench: 'https://ibis.ibo.org/?language=FRENCH',
    ibisUrlSpanish: 'https://ibis.ibo.org/?language=SPANISH'
}

export const STOREURL = {
    storeUrlEnglish: 'http://www.follettibstore.com/main/home',
    storeUrlFrench: 'http://www.follettibstore.com/main/home',
    storeUrlSpanish: 'http://www.follettibstore.com/main/home'
}

export const BLOGSURL = {
    blogsUrlEnglish: 'http://blogs.ibo.org/',
    blogsUrlFrench: 'http://blogs.ibo.org/?lang=fr',
    blogsUrlSpanish: 'http://blogs.ibo.org/?lang=es'
}

export const ASKQUESTIONURL = {
    askQuestionUrlEnglish: 'https://ibo.org/contact-the-ib/ask-a-question/',
    askQuestionUrlFrench: 'https://ibo.org/fr/contact-the-ib/ask-a-question/',
    askQuestionUrlSpanish: 'https://ibo.org/es/contact-the-ib/ask-a-question/'
}

export const COPYRIGHT = {
    copyrightUrlEnglish: 'https://ibo.org/terms-and-conditions/copyright/',
    copyrightUrlFrench: 'https://ibo.org/fr/terms-and-conditions/copyright/',
    copyrightUrlSpanish: 'https://ibo.org/es/terms-and-conditions/copyright/'
}

export const LANGUAGEPOLICY = {
    languagePolicyUrlEnglish: 'https://ibo.org/language-policy/',
    languagePolicyUrlFrench: 'https://ibo.org/fr/language-policy/',
    languagePolicyUrlSpanish: 'https://ibo.org/es/language-policy/'
}

export const TERMSANDCONDITION = {
    termsAndConditionsUrlEnglish: 'https://ibo.org/terms-and-conditions/',
    termsAndConditionsUrlFrench: 'https://ibo.org/fr/terms-and-conditions/',
    termsAndConditionsUrlSpanish: 'https://ibo.org/es/terms-and-conditions/'
}

export const IBPRIVACYPOLICY = {
    ibPrivacyPolicyUrlEnglish: 'https://ibo.org/terms-and-conditions/privacy-policy/',
    ibPrivacyPolicyUrlFrench: 'https://ibo.org/fr/terms-and-conditions/privacy-policy/',
    ibPrivacyPolicyUrlSpanish: 'https://ibo.org/es/terms-and-conditions/privacy-policy/'
}

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    navBar: NavBar;
    footerLink: FooterLink;
    termsAndPolicy: TermsAndPolicy;

    constructor(private jwtTokenService: JWTTokenService) {
        this.navBar = { iboUrl: '', ibisUrl: '', myibUrl: '', storeUrl: '', blogsUrl: '', askQuestionUrl: '' };
        this.footerLink = { copyrightUrl: '', languagePolicyUrl: '', termsAndConditionsUrl: '', iBprivacyPolicyUrl: '' };
        this.termsAndPolicy = { termsAndConditionsUrl: '', iBprivacyPolicyUrl: '' };
    }

    public navBarUrl(language: string): NavBar {
        if (language === 'en') {
            this.navBar.iboUrl = IBOURL.iboUrlEnglish;
            this.navBar.ibisUrl = IBISURL.ibisUrlEnglish;
            this.navBar.myibUrl = MYIBURL.myIbUrlEnglish;
            this.navBar.storeUrl = STOREURL.storeUrlEnglish;
            this.navBar.blogsUrl = BLOGSURL.blogsUrlEnglish;
            this.navBar.askQuestionUrl = ASKQUESTIONURL.askQuestionUrlEnglish;
        } else if (language === 'fr') {
            this.navBar.iboUrl = IBOURL.iboUrlFrench;
            this.navBar.ibisUrl = IBISURL.ibisUrlFrench;
            this.navBar.myibUrl = MYIBURL.myIbUrlFrench;
            this.navBar.storeUrl = STOREURL.storeUrlFrench;
            this.navBar.blogsUrl = BLOGSURL.blogsUrlFrench;
            this.navBar.askQuestionUrl = ASKQUESTIONURL.askQuestionUrlFrench;
        } else if (language === 'sp') {
            this.navBar.iboUrl = IBOURL.iboUrlSpanish;
            this.navBar.ibisUrl = IBISURL.ibisUrlSpanish;
            this.navBar.myibUrl = MYIBURL.myIbUrlSpanish;
            this.navBar.storeUrl = STOREURL.storeUrlSpanish;
            this.navBar.blogsUrl = BLOGSURL.blogsUrlSpanish;
            this.navBar.askQuestionUrl = ASKQUESTIONURL.askQuestionUrlSpanish;
        }
        return this.navBar;
    }
    public footerLinkUrl(language: string): FooterLink {
        if (language === 'en') {
            this.footerLink.copyrightUrl = COPYRIGHT.copyrightUrlEnglish;
            this.footerLink.languagePolicyUrl = LANGUAGEPOLICY.languagePolicyUrlEnglish;
            this.footerLink.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlEnglish;
            this.footerLink.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlEnglish;
        } else if (language === 'fr') {
            this.footerLink.copyrightUrl = COPYRIGHT.copyrightUrlFrench;
            this.footerLink.languagePolicyUrl = LANGUAGEPOLICY.languagePolicyUrlFrench;
            this.footerLink.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlFrench;
            this.footerLink.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlFrench;
        } else if (language === 'sp') {
            this.footerLink.copyrightUrl = COPYRIGHT.copyrightUrlSpanish;
            this.footerLink.languagePolicyUrl = LANGUAGEPOLICY.languagePolicyUrlSpanish;
            this.footerLink.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlSpanish;
            this.footerLink.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlSpanish;
        }
        return this.footerLink;
    }

    public getTermsPolicy(language: string): TermsAndPolicy {
        if (language === 'en') {
            this.termsAndPolicy.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlEnglish;
            this.termsAndPolicy.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlEnglish;
        } else if (language === 'fr') {
            this.termsAndPolicy.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlFrench;
            this.termsAndPolicy.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlFrench;
        } else if (language === 'sp') {
            this.termsAndPolicy.termsAndConditionsUrl = TERMSANDCONDITION.termsAndConditionsUrlSpanish;
            this.termsAndPolicy.iBprivacyPolicyUrl = IBPRIVACYPOLICY.ibPrivacyPolicyUrlSpanish;
        }
        return this.termsAndPolicy;
    }

    public isExternalUser(): boolean {
        let isExternal = true;
        let domain = environment.userTypeDomain;
        let user = this.jwtTokenService.getUser();
        let emailData: [] = user.email.split('@');
        if (emailData[emailData.length - 1] == domain) {
            isExternal = false;
        }
        return isExternal;
    }

    public getVideoUrl(language: string): any {
        let url = '';
        if (language === 'en') {
            url = 'assets/images/en_IBInsights_Tutorial.mp4';
        } else if (language === 'fr') {
            url = 'assets/images/French_IB_Tutorial.mp4';
        } else if (language === 'sp') {
            url = 'assets/images/Spanish_IB_tutoriel.mp4';
        }
        return url;
    }

}