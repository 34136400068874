import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { HelperService } from '../comman/helper.service';


const API_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: User;
    userEmail: string = '';

    constructor(private http: HttpClient,private helperService: HelperService) {
    }

    saveUser(user: User): Observable<User> {
        let data = JSON.stringify(user);
        return this.http.post(`${API_URL}/user/add`, data).pipe(
            map((response: any) => {
                return response;
            }));
    }

    updateUser(user: User): Observable<User> {
        let data = JSON.stringify(user);
        return this.http.post(`${API_URL}/user/update`, data).pipe(
            map((response: any) => {
                return response;
            }));
    }

    getUserById(): Observable<User> {
        return this.http.get(`${API_URL}/user`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    checkUserExist(emailId: string): Observable<boolean> {
        return this.http.get(`${API_URL}/user/userexist/${emailId}`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    savePreferredLanguage(prefLanguage: string): Observable<any> {
        return this.http.get(`${API_URL}/user/savelanguage/${prefLanguage}`).pipe(
            map((response: any) => {
                return response;
            }));
    }

    setUser(user: User): void {
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUser(): User {
        let user = JSON.parse(localStorage.getItem('user'));
        return user;
    }

    setUserType(userType: string) {
        localStorage.setItem('userType', JSON.stringify(userType));
    }

    getUserType() {
        return JSON.parse(localStorage.getItem('userType'));
    }
}
