export class User{
personCode: string;
name: string;
emailId: string;
schoolCode: string;
schoolName: string;
programme: string
role: string;
schoolCountry: string;
regionalComposition: string;
geographicRegion: string;
schoolState: string;
preferredLanguage:string;
legalStatus: string;
active: string;
isAgree:boolean;

constructor(private user?: User) {
    user && user.personCode ? this.personCode = user.personCode : this.personCode = "";
    user && user.name ? this.name = user.name : this.name = "";
    user && user.emailId ? this.emailId = user.emailId : this.emailId = "";
    user && user.schoolCode ? this.schoolCode = user.schoolCode : this.schoolCode = "";
    user && user.schoolName ? this.schoolName = user.schoolName : this.schoolName = "";
    user && user.programme ? this.programme = user.programme : this.programme = "";
    user && user.role ? this.role = user.role : this.role = "";
    user && user.schoolCountry ? this.schoolCountry = user.schoolCountry : this.schoolCountry = "";
    user && user.regionalComposition ? this.regionalComposition = user.regionalComposition : this.regionalComposition = "";
    user && user.geographicRegion ? this.geographicRegion = user.geographicRegion : this.geographicRegion = "";
    user && user.schoolState ? this.schoolState = user.schoolState : this.schoolState = "";
    user && user.preferredLanguage ? this.preferredLanguage = user.preferredLanguage : this.preferredLanguage = "";
    user && user.legalStatus ? this.legalStatus = user.legalStatus : this.legalStatus = "";
    user && user.active ? this.active = user.active : this.active = "";
    user && user.isAgree ? this.isAgree = user.isAgree : this.isAgree = false;
}
}