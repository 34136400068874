import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/report/report.component';
import { UserListComponent } from './components/user/user-list.component';
import { NotificationListComponent } from './components/notification/notification-list.component';
import { HttpConfigInterceptor } from './comman/httpconfig.intercepter';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './comman/filter-pipe';
import { ExportDirective } from './comman/exporttoexcel.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { NgbdSortableHeader } from './comman/directive/sorttable.directive';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UserIdleModule } from 'angular-user-idle';
import { AzureAdUserComponent } from './components/user/azure-ad-user.component';

import { AuthorizationService } from './services/authorization.service';
import { environment  } from '../environments/environment';
import { Requestor, FetchRequestor } from '@openid/appauth';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { SqaHelpguideComponent } from './components/helpguide/sqa-helpguide/sqa-helpguide.component';
import { AvpHelpguideComponent } from './components/helpguide/avp-helpguide/avp-helpguide.component';
import { IaaHelpguideComponent } from './components/helpguide/iaa-helpguide/iaa-helpguide.component';
import { AaccessdeniedComponent } from './components/accessdenied/accessdenied.component';


@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    HomeComponent,
    ReportComponent,
    UserListComponent,
    NotificationListComponent,
    HeaderComponent,
    FooterComponent,
    FilterPipe,
    AzureAdUserComponent,
    ExportDirective,
    CallbackComponent,
    NgbdSortableHeader,
    AuthenticationComponent,
    SqaHelpguideComponent,
    AvpHelpguideComponent,
    IaaHelpguideComponent,
    AaccessdeniedComponent
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    UserIdleModule.forRoot({idle: 900, timeout: 15}),
    NgxDaterangepickerMd.forRoot(),
    NgbModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule.forRoot(routes),
   // RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RouterModule,
    TranslateModule
  ], 
  providers: [
  AuthorizationService,
     { provide: Requestor, useValue: new FetchRequestor()},
     { provide: 'AuthorizationConfig', useValue: environment},
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
