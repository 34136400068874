import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JWTTokenService } from './services/JWTTokenService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // Inject Router so we can hand off the user to the Login Page 
  constructor(private router: Router, private jwtTokenService: JWTTokenService) {}
 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
 
      if (this.jwtTokenService.getUser()) {
       // if (this.jwtTokenService.isTokenExpired()) {
       //   this.router.navigate(['authenticate']);
       // } else {
          return true;
       // }
       } else  {
         // Token from the LogIn is not avaible because something went wrong or the user wants to go over the url to the site
         // Hands the user to the LogIn page 
         this.router.navigate(['authenticate']);
         return false
       }
  }
}
