import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'iaa-helpguide',
  templateUrl: './iaa-helpguide.component.html',
  styleUrls: ['./iaa-helpguide.component.css']
})
export class IaaHelpguideComponent implements OnInit {
  @Output() closeHelpGide = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  closeNav(){
    this.closeHelpGide.emit();
  }
}
