export class ReportPdf {
    language: string;
    reportType: string;
    bookmarkState: string;
    filters: string;
    constructor(data?: any) {
        data && data.language ? this.language = data.language : this.language = "";
        data && data.reportType ? this.reportType = data.reportType : this.reportType = "";
        data && data.bookmarkState ? this.bookmarkState = data.bookmarkState : this.bookmarkState = "";
        data && data.filters ? this.filters = data.filters : this.filters = "";
    }
}