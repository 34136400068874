export class EmbedConfig {
    id: string;
    embedUrl: string;
    embedToken: EmbedToken;
    embeddedToken:string;
    expiration: string;
    isEffectiveIdentityRolesRequired: boolean;
    isEffectiveIdentityRequired: boolean;
    enableRLS: boolean;
    userName: string;
    roles: string;
    errorMessage: string;

    constructor(private embedConfig?: EmbedConfig) {
        embedConfig && embedConfig.id ? this.id = embedConfig.id : this.id = null;
        embedConfig && embedConfig.embedUrl ? this.embedUrl = embedConfig.embedUrl : this.embedUrl = "";
        embedConfig && embedConfig.embedToken ? this.embedToken = new EmbedToken(embedConfig.embedToken) : this.embedToken = new EmbedToken();// new Date();
        embedConfig && embedConfig.expiration ? this.expiration = embedConfig.expiration : this.expiration = null;
        embedConfig && embedConfig.isEffectiveIdentityRequired ? this.isEffectiveIdentityRequired = embedConfig.isEffectiveIdentityRequired : this.isEffectiveIdentityRequired = false;
        embedConfig && embedConfig.enableRLS ? this.enableRLS = embedConfig.enableRLS : this.enableRLS = false;
        embedConfig && embedConfig.userName ? this.userName = embedConfig.userName : this.userName = null;
        embedConfig && embedConfig.roles ? this.roles = embedConfig.roles : this.roles = null;
        embedConfig && embedConfig.errorMessage ? this.errorMessage = embedConfig.errorMessage : this.errorMessage = null;
        embedConfig && embedConfig.embeddedToken ? this.embeddedToken = embedConfig.embeddedToken : this.embeddedToken = null;// new Date();
    }
}

export class EmbedToken {
    token: string;
    tokenId: string
    expiration: string;
    constructor(private embedToken?: EmbedToken){
        embedToken && embedToken.tokenId ? this.tokenId = embedToken.tokenId : this.tokenId = null;
        embedToken && embedToken.token ? this.token = embedToken.token : this.token = null;
        embedToken && embedToken.expiration ? this.expiration = embedToken.expiration : this.expiration = null;
        
    }
}
