import { OnInit, Component, QueryList, ViewChildren } from '@angular/core';

import { ReportService } from '../../services/report.service';
import { AccessedReport } from '../../models/accessedreport';
import { NgbdSortableHeader, SortEvent } from '../../comman/directive/sorttable.directive';
import { NgxSpinnerService } from 'ngx-spinner';

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  userList: AccessedReport[];
  page = 1;
  pageSize = 2;
  collectionSize = 0;
  filterUser: string = '';

  constructor(private reportService: ReportService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getUserListReportDetail();
  }

  private getUserListReportDetail() {
    this.spinner.show();
    this.reportService.getUserListReportDetails().subscribe(users => {
      this.spinner.hide();
      this.userList = users;
      this.collectionSize = this.userList.length;
    });
  }

  get usersList(): AccessedReport[] {
    return this.userList && this.userList.length > 0 ? this.userList.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize) : [];
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting users
    if (direction === '' || column === '') {
      this.userList;
    } else {
      this.userList = this.userList.sort((a, b) => {
        const res = compare(`${a[column]}`, `${b[column]}`);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}