export class Programme{
    id: number;                   
    personCode: string; 
    schoolCode: string;
    schoolName: string;
    programmeName: string;
    abbr: string;

    constructor(private programme: Programme) {
        programme && programme.id ? this.id = programme.id : this.id = 0;
        programme && programme.personCode ? this.personCode = programme.personCode : this.personCode = "";
        programme && programme.schoolCode ? this.schoolCode = programme.schoolCode : this.schoolCode = "";
        programme && programme.schoolName ? this.schoolName = programme.schoolName : this.schoolName = "";
        programme && programme.programmeName ? this.programmeName = programme.programmeName : this.programmeName = "";
        programme && programme.abbr ? this.abbr = programme.abbr : this.abbr = "";
    }
}